<!-- 清洁能源设置 -->
<template>
  <div id="cleanEnergySetting" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    <div class="pageTitle">
        <my-PageTitle>{{ $t('cleanEnergySetting.a1') }}</my-PageTitle>
    </div>
    <!-- 侧边栏 -->
    <div class="index">
        <div class="index-sider">
            <div class="index-sider-menu">
                <a-tree
                    :blockNode="true"
                    :tree-data="cleanEnergyGroup"
                    :defaultExpandAll="true"
                    @select="getData"
                >
                    <template slot="custom" slot-scope="item">
                        <div class="tree-container">
                            <span>{{item.name}}</span>
                            <div class="tree-icon">
                                <a-icon type="plus-square" v-if="item.level == 1" style="font-size: 16px" @click.stop="openAddGroup(item)"/>
                                <a-icon type="form" v-if="item.level !== 1" style="font-size: 16px;margin-right:10px" @click.stop="openNameEdit(item)"/>
                                <a-icon type="delete" v-if="item.level !== 1" style="font-size: 16px" @click.stop="groupDelete(item)"/> 
                            </div>
                        </div>
                    </template>
                </a-tree>
            </div>
        </div>
        <div class="index-main">
            <div class="empty" v-if="setData.purchasePoints == undefined">
                <a-empty>
                    <span class="explain">
                        {{ $t('cleanEnergySetting.a2') }}
                    </span>
                </a-empty>
            </div>
            <div class="index-main-content" v-else>
                <!-- 总发电量 -->
                <div class="row">
                    <div class="row-title">
                        {{ $t('cleanEnergySetting.a3') }}
                    </div>
                    <div>
                        <a-tree-select
                            v-model="setData.generatingPoints"
                            :treeDefaultExpandedKeys="setData.generatingPoints"
                            style="width: 550px"
                            :tree-data="setData.measurePointList"
                            :placeholder="$t('cleanEnergySetting.a2')"
                            :replaceFields="fieldNames"
                            :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
                            v-if="reset"
                            multiple
                        />
                    </div>
                </div>
                <!-- 并网电量 -->
                <div class="row">
                    <div class="row-title">
                        {{ $t('cleanEnergySetting.a4') }}
                    </div>
                    <div>
                        <a-tree-select
                            v-model="setData.mergeGridPoints"
                            :treeDefaultExpandedKeys="setData.mergeGridPoints"
                            style="width: 550px"
                            :tree-data="setData.measurePointList"
                            :placeholder="$t('cleanEnergySetting.a2')"
                            :replaceFields="fieldNames"
                            :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
                            v-if="reset"
                            multiple
                        />
                    </div>
                </div>
                <!-- 自用电量 -->
                <div class="row">
                    <div class="row-title">
                        {{ $t('cleanEnergySetting.a5') }}
                    </div>
                    <div>
                        <a-tree-select
                            v-model="setData.selfUsePoints"
                            :treeDefaultExpandedKeys="setData.selfUsePoints"
                            style="width: 550px"
                            :tree-data="setData.measurePointList"
                            :placeholder="$t('cleanEnergySetting.a2')"
                            :replaceFields="fieldNames"
                            :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
                            v-if="reset"
                            multiple
                        />
                    </div>
                </div>
                <!-- 总用电量 -->
                <div class="row">
                    <div class="row-title">
                        {{ $t('cleanEnergySetting.a6') }}
                    </div>
                    <div>
                        <a-tree-select
                            v-model="setData.totalUsePoints"
                            :treeDefaultExpandedKeys="setData.totalUsePoints"
                            style="width: 550px"
                            :tree-data="setData.measurePointList"
                            :placeholder="$t('cleanEnergySetting.a2')"
                            :replaceFields="fieldNames"
                            :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
                            v-if="reset"
                            multiple
                        />
                    </div>
                </div>
                <!-- 电网取电 -->
                <div class="row">
                    <div class="row-title">
                        {{ $t('cleanEnergySetting.a7') }}
                    </div>
                    <div>
                        <a-tree-select
                            v-model="setData.purchasePoints"
                            :treeDefaultExpandedKeys="setData.purchasePoints"
                            style="width: 550px"
                            :tree-data="setData.measurePointList"
                            :placeholder="$t('cleanEnergySetting.a2')"
                            :replaceFields="fieldNames"
                            :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
                            v-if="reset"
                            multiple
                        />
                    </div>
                </div>
                <div class="butcontent">
                    <a-button type="primary" @click="resetData">
                        {{ $t('cleanEnergySetting.a9') }}
                    </a-button>
                    <a-button type="primary" ghost style="margin-left:20px" @click="dataSetting">
                        {{ $t('cleanEnergySetting.a10') }}
                    </a-button>
                </div>
            </div>
        </div>
    </div>
    <!-- 添加分组 -->
    <a-modal v-model="addGroupVisible" :title="$t('cleanEnergySetting.a11')" centered :width="300" @cancel="addGroupCancel" :destroyOnClose="true" v-drag>
        <template slot="footer">
            <a-button key="back" @click="addGroupCancel">
                {{ $t('cleanEnergySetting.a12') }}
            </a-button>
            <a-button key="submit" type="primary" @click="addGroupOK" :disabled="disabled">
                {{ $t('cleanEnergySetting.a13') }}
            </a-button>
        </template>
        <div v-loading="addGroupLoading" :element-loading-text="$t('alarm.a0')">
            <div class="padding">
                <a-form-model ref="ruleForm" :model="addData" :rules="rules">
                    <a-form-model-item :label="$t('cleanEnergySetting.a14')" prop="name">
                        <a-input v-model="addData.name" :maxLength="30" :placeholder="$t('cleanEnergySetting.a15')" allow-clear style="width: 200px"/>
                    </a-form-model-item>
                </a-form-model>
            </div>       </div>
    </a-modal>
    <!-- 分组名称修改 -->
    <a-modal v-model="nameEditVisible" :title="$t('cleanEnergySetting.a16')" centered :width="300" @cancel="nameEditCancel" :destroyOnClose="true" v-drag>
        <template slot="footer">
            <a-button key="back" @click="nameEditCancel">
                {{ $t('cleanEnergySetting.a12') }}
            </a-button>
            <a-button key="submit" type="primary" @click="nameEditOK" :disabled="disabled">
                {{ $t('cleanEnergySetting.a13') }}
            </a-button>
        </template>
        <div v-loading="editGroupLoading" :element-loading-text="$t('alarm.a0')">
            <div class="padding">
                <a-form-model ref="Form" :model="nameEdit" :rules="rules">
                    <a-form-model-item :label="$t('cleanEnergySetting.a14')" prop="name">
                        <a-input v-model="nameEdit.name" :maxLength="30" :placeholder="$t('cleanEnergySetting.a15')" allow-clear style="width: 200px"/>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
    </a-modal>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import { getCleanEnergySetGroupList,getCleanEnergySetGroupEdit,getCleanEnergySetGroupDelete } from '../../api/energy'

export default {
    name:'cleanEnergySetting',
    components:{
        'my-PageTitle':PageTitle
    },
    data(){
        return{
            loading:false,
            cleanEnergyGroup:[],
            addGroupVisible:false,
            disabled:false,
            addGroupLoading:false,
            addData:{
                name:''
            },
            rules: {
                name: [
                    { required: true, message: this.$t('cleanEnergySetting.a15'), trigger: 'change' },
                ],
            },
            nameEditVisible:false,
            editGroupLoading:false,
            nameEdit:{
                name:''
            },
            nodeCache:{},
            setData:{},
            setDataCopy:{},
            fieldNames:{ label: 'name', value: 'key', children: 'children' },
            reset:true,
            nodeCache:{}
        }
    },
    // 清洁能源设置页面侧边栏数据获取
    mounted(){
        this.loading = true
        const data = {
            action:0,
            clientId:this.$store.getters.clientId,
            sitegrId:this.$store.getters.sitegrId
        }
        getCleanEnergySetGroupList(data)
        .then(res=>{
            console.log(res)
            const { data:{cleanEnergyGroup} } = res
            this.recursion(cleanEnergyGroup)
            this.cleanEnergyGroup = cleanEnergyGroup
            this.loading = false
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods:{
        // 打开分组添加弹框
        openAddGroup(item){
            this.nodeCache = item
            this.addGroupVisible = true
        },
        // 关闭分组添加弹框
        addGroupCancel(){
            this.addGroupVisible = false
            this.addData.name = ''
        },
        // 添加分组API
        addGroupOK(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.addGroupLoading = true
                    const clientId = this.$store.getters.clientId
                    const sitegrId = this.$store.getters.sitegrId
                    const cleanEnergyGroupId = 0
                    const cleanEnergyGroupName =  this.addData.name
                    const data = {
                        action:0,
                        clientId,
                        sitegrId,
                        parentId:0,
                        cleanEnergyGroupId,
                        cleanEnergyGroupName
                    }
                    getCleanEnergySetGroupEdit(data)
                    .then(res=>{
                        // console.log(res)
                        this.addGroupLoading = false
                        const { data:{result}} = res
                        if(res.errorCode == '03'){
                            this.$message.success(res.msg)
                            result.scopedSlots = { title: 'custom' }
                            this.nodeCache.children.push(result)
                            this.addGroupCancel()
                        }else{
                            this.$message.error(res.msg)
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                        this.addGroupLoading = false
                    })
                } else {
                    return false
                }
            })
        },
        // 打开分组名称修改弹框
        openNameEdit(item){
            console.log(item)
            this.nodeCache = item.dataRef
            this.nameEdit.name = item.name
            this.nameEditVisible = true
        },
        // 关闭分组名称修改弹框
        nameEditCancel(){
            this.nameEditVisible = false
        },
        // 分组名称修改API
        nameEditOK(){
            this.$refs.Form.validate(valid => {
                if (valid) {
                    this.editGroupLoading = true
                    const clientId = this.$store.getters.clientId
                    const sitegrId = this.$store.getters.sitegrId
                    const cleanEnergyGroupId = this.nodeCache.no
                    const cleanEnergyGroupName =  this.nameEdit.name
                    const data = {
                        action:0,
                        clientId,
                        sitegrId,
                        parentId:0,
                        cleanEnergyGroupId,
                        cleanEnergyGroupName
                    }
                    getCleanEnergySetGroupEdit(data)
                    .then(res=>{
                        // console.log(res)
                        this.editGroupLoading = false
                        const { data:{result}} = res
                        if(res.errorCode == '03'){
                            this.$message.success(res.msg)
                            this.nodeCache.name = result.name
                            this.nameEditCancel()
                        }else{
                            this.$message.error(res.msg)
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                        this.editGroupLoading = false
                    })
                } else {
                    return false
                }
            })
        },
        // 删除分组
        groupDelete(item){
            console.log(item)
            this.$confirm({
                title: `${this.$t('cleanEnergySetting.a17')}${item.name}?`,
                centered: true,
                onOk:()=> {
                    return new Promise((resolve, reject) => {
                      getCleanEnergySetGroupDelete(item.no)
                      .then(res=>{
                        console.log(res)
                        if(res.errorCode == '05'){
                            const data = {
                                action:0,
                                clientId:this.$store.getters.clientId,
                                sitegrId:this.$store.getters.sitegrId
                            }
                            getCleanEnergySetGroupList(data)
                            .then(res=>{
                                console.log(res)
                                const { data:{cleanEnergyGroup} } = res
                                this.recursion(cleanEnergyGroup)
                                this.cleanEnergyGroup = cleanEnergyGroup
                                this.setData = {}
                                this.$message.success(res.msg)
                                resolve()
                            })
                            .catch(err=>{
                                console.log(err)
                                this.loading = false
                            })
                        }else{
                            this.$message.error(res.msg)
                        }
                      })
                      .catch(err=>{
                        console.log(err)
                        reject()
                      })
                    }).catch(() => { return false})
                },
            })
        },
        // 获取去分组详情数据
        getData(selectedKeys, info){
            console.log(info.selected)
            if(info.selected){
                const cleanEnergyGroupId = info.node.dataRef.no
                this.loading = true
                this.reset = false
                const clientId = this.$store.getters.clientId
                const sitegrId = this.$store.getters.sitegrId
                const data = {
                    action:1,
                    clientId,
                    sitegrId,
                    cleanEnergyGroupId
                }
                getCleanEnergySetGroupList(data)
                .then(res=>{
                    console.log(res)
                    this.loading = false
                    this.reset = true
                    const { data } = res
                    this.setData = data
                    const _setDataCopy = JSON.stringify(data)
                    const setDataCopy = JSON.parse(_setDataCopy)
                    this.setDataCopy = setDataCopy
                })
                .catch(err=>{
                    console.log(err)
                    this.loading = false
                    this.reset = true
                })
            }
        },
        // 重置数据
        resetData(){
            const _setData = JSON.stringify(this.setDataCopy)
            const setData = JSON.parse(_setData)
            this.setData = setData
        },
        // 数据设置
        dataSetting(){
            this.loading = true
            const clientId = this.$store.getters.clientId
            const sitegrId = this.$store.getters.sitegrId
            const cleanEnergyGroupId = this.setData.cleanEnergyGroupId
            const generatingPoints = this.setData.generatingPoints
            const mergeGridPoints = this.setData.mergeGridPoints
            const selfUsePoints = this.setData.selfUsePoints
            const totalUsePoints = this.setData.totalUsePoints
            const purchasePoints = this.setData.purchasePoints
            const data = {
                action:1,
                clientId,
                sitegrId,
                cleanEnergyGroupId,
                generatingPoints,
                mergeGridPoints,
                selfUsePoints,
                totalUsePoints,
                purchasePoints
            }
            getCleanEnergySetGroupEdit(data)
            .then(res=>{
                // console.log(res)
                const { data:{generatingPoints,mergeGridPoints,purchasePoints,selfUsePoints,totalUsePoints} } = res
                if(res.errorCode == '03'){
                    this.$message.success(res.msg)
                    this.setDataCopy.generatingPoints = generatingPoints
                    this.setDataCopy.mergeGridPoints = mergeGridPoints
                    this.setDataCopy.purchasePoints = purchasePoints
                    this.setDataCopy.selfUsePoints = selfUsePoints
                    this.setDataCopy.totalUsePoints = totalUsePoints
                }else{
                    this.$message.error(res.msg)
                }
                this.loading = false
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 递归方法
        recursion(arr){
            for(let i = 0;i < arr.length;i++){
                if(arr[i].level == 1 && arr[i].children == undefined){ arr[i].children = [] }
                arr[i].scopedSlots = { title: 'custom' }
                if(arr[i].children){ this.recursion(arr[i].children) }
            }
        }
    }
}
</script>

<style scoped>
#cleanEnergySetting{
    width: 100%;
    height: 100%;
}
div /deep/.ant-modal-header{
  background-color: #7682CE;
}
.index{
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
}
.index-sider{
    width: 300px;
    height: 100%;
    padding-top: 20px;
    overflow: hidden;
}
.tree-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
}
.tree-icon{
    display: flex;
    align-items: center;
}
.index-sider-menu{
    width: 300px;
    height: 100%;
    padding-left: 15px;
    border-right: 1px solid rgba(12, 12, 12, 0.25);
    overflow: auto;
}
.menu{
    display: flex;
    justify-content: space-between;
}
.menu-item{
    display: flex;
    justify-content: space-between;
}
.butcontent{
    display: flex;
    justify-content: space-between;
}
.index-main{
    width: calc(100% - 300px);
}
.index-main-content{
    height: 100%;
    padding: 20px;
    overflow: auto;
}
.empty{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.row{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 3px;
    box-shadow: 0px 2px 5px rgb(12, 12, 12 , 0.25);
    margin-bottom: 15px;
}
.row-title{
    width: 120px;
    font-size: 16px;
    color: rgb(12, 12, 12 , 0.65);
}
.explain{
    font-size: 20px;
    color: #7682ce;
}
</style>